import { render, staticRenderFns } from "./b-auto-complete-dropdown3v2.vue?vue&type=template&id=15399929&scoped=true"
import script from "./b-auto-complete-dropdown3v2.vue?vue&type=script&lang=js"
export * from "./b-auto-complete-dropdown3v2.vue?vue&type=script&lang=js"
import style0 from "./b-auto-complete-dropdown3v2.vue?vue&type=style&index=0&id=15399929&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15399929",
  null
  
)

export default component.exports