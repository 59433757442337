<template>
  <b-flip-modal @close="close">
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="modal-header">
          <span class="com-title">{{ title }}</span>
        </div>
        <div v-if="error" class="error">{{ error }}</div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="pixelId">Pixel ID</label>
              <input id="pixelId" v-model="pixel.flip_id" type="text" disabled />
            </div>
            <div class="form-group">
              <label for="name">Pixel Name</label>
              <input id="name" v-model="pixel.name" type="text" aria-describedby="emailHelp" />
            </div>
            <div class="form-group">
              <label for="xandrID">DSP ID</label>
              <input id="xandrID" v-model="pixel.apn_adv_id" type="text" disabled />
            </div>
            <div class="form-group">
              <label for="xandrName">DSP Name</label>
              <input id="xandrName" v-model="pixel.apn_name" type="text" />
            </div>
            <div class="form-group">
              <label for="piggybackPixels">Piggyback Pixels</label>
              <input id="piggybackPixels" v-model="piggybackPixel" type="text" />
              <span class="add-btn" @click="addPiggybackPixel">Add</span>
              <div class="list-url">
                <p v-for="(pp, pi) in pixel.piggyback_pixels" :key="pi">
                  {{ pp }}<span @click="removePiggybackPixel(pi)">&#215;</span>
                </p>
              </div>
            </div>
            <div class="form-group form-check">
              <label class="form-check-label" for="pixelActive">Active</label>
              <k-checkbox
                :checked="pixel.active"
                name="pixel.active"
                @change="setActive"
                @selected="setActive"
              />
            </div>
            <div class="form-group" style="text-align: right; float:right; display: contents;">
              <div class="actions-btn">
                <k-button :size="3" label="SAVE" class="mr-20" @click="handlePixel" />
                <k-button :size="3" label="CLOSE" @click="close" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </b-flip-modal>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import flipPixelApi from '~/api/flipPixel';
import advertiserApi from '~/api/advertiser';

export default {
  components: {
    BFlipModal: () =>
      import(/* webpackChunkName: "b-flip-modal" */ '~/components/elements/b-flip-modal.vue'),
  },
  props: {
    initialPixel: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
    advertiserInView: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
  },
  data() {
    const pixel = JSON.parse(JSON.stringify(this.initialPixel));
    pixel.piggyback_pixels = pixel.piggyback_pixels || [];

    return {
      pixel,
      loading: false,
      piggybackPixel: '',
      errorMsg: null,
    };
  },
  computed: {
    title() {
      return this.initialPixel.flip_id ? 'Edit Pixel' : 'New Pixel';
    },
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
  },
  methods: {
    setActive(val) {
      this.pixel.active = val.checked;
    },
    async handlePixel() {
      try {
        if (!this.initialPixel.flip_id) {
          await this.addPixel();
        } else {
          await this.updatePixel();
        }
      } catch (err) {
        console.error('error handling pixel ->', err);
      }
    },
    validateData() {
      if (isBlank(this.pixel.name)) {
        this.errorMsg = 'Please Enter Pixel Name';
        return false;
      }

      if (isBlank(this.pixel.apn_name)) {
        this.errorMsg = 'Please Enter Xandr Name';
        return false;
      }

      this.errorMsg = null;
      return true;
    },
    async addPixel() {
      const dataValidated = this.validateData();
      if (!dataValidated) {
        return;
      }

      this.loading = true;
      const pixel = JSON.parse(JSON.stringify(this.pixel));

      pixel.conversion_type = 'Universal';

      try {
        await flipPixelApi.createFlipPixel(this.advertiserInView.id, pixel);
        if (this.advertiserInView?.selfServe) {
          await this.updateAdvertiser();
        }
      } catch (err) {
        this.errorMsg = 'Failed while adding the pixel';
        console.error('error add pixel api ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
        if (isBlank(this.errorMsg)) {
          this.close();
        }
      }
    },
    async updateAdvertiser() {
      if (!this.advertiserInView.pixelSetup) {
        this.advertiserInView.pixelSetup = {};
      }
      this.advertiserInView.pixelSetup.pixelAdded = true;
      try {
        await advertiserApi.editAdvertiser(this.advertiserInView.id, this.advertiserInView);
        await advertiserApi.pixelReadyEmailNotification(this.advertiserInView.id);
      } catch (err) {
        this.errorMsg = 'Failed while updating the advertiser';
        console.error('error edit advertiser api ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async updatePixel() {
      const dataValidated = this.validateData();
      if (!dataValidated) {
        return;
      }

      this.loading = true;
      const pixel = JSON.parse(JSON.stringify(this.pixel));

      try {
        await flipPixelApi.editFlipPixel([this.advertiserInView.id, pixel.flip_id], pixel);
      } catch (err) {
        this.errorMsg = 'Failed while updating the pixel';
        console.error('error update pixel api ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
        if (isBlank(this.errorMsg)) {
          this.close();
        }
      }
    },
    close() {
      this.$emit('close');
    },
    addPiggybackPixel() {
      if (isBlank(this.piggybackPixel)) {
        return;
      }
      this.pixel.piggyback_pixels.push(this.piggybackPixel);
      this.piggybackPixel = '';
    },
    removePiggybackPixel(i) {
      this.pixel.piggyback_pixels.splice(i, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }
  .modal-wrapper {
    margin: 0 auto;
  }
}
/**custom */
.list-box {
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: #212429;
  border-radius: 0;
  padding: 30px 20px;
  width: 100%;
  display: block;
  min-width: 800px;
  max-width: 800px;
  margin: 0 auto;
  ul {
    margin-bottom: 10px;
    ul {
      border: 0;
      margin: 0;
    }
    li {
      font-size: 12px;
      line-height: 0.75rem;
      color: rgba(130, 130, 140, 0.8);
      padding-bottom: 5px;
      padding-top: 5px;
      margin-bottom: 0;
      position: relative;

      li {
        border-bottom: 1px solid rgba(170, 170, 170, 0.1);
        padding: 15px 15px 15px 10px;
        list-style: none;
        list-style-position: inside;
        font-size: 13px;
        a {
          color: #848890;
        }
        .box {
          svg {
            color: #848890;
            &:hover {
              color: var(--adminprimarycolor) !important;
            }
          }
        }
      }
      a {
        color: #cad1d6;
        display: inline;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .button-box {
    position: absolute;
    top: 5px;
    right: 0;
    line-height: 30px;
    .box {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
.modal-wrapper-box {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  padding: 15px;
  margin: 0 auto;
  color: #666;
  background-color: #212429;
  font-size: 12px;
  line-height: 0.75rem;
  color: rgba(130, 130, 140, 0.8);
  .modal-header {
    margin-bottom: 10px;
    h2 {
      text-align: center;
      color: #fff;
      line-height: 1.3;
      padding-bottom: 10px;
    }
    p {
      border-top: 2px solid white;
      text-align: center;
      font-size: 18px;
      padding: 20px 0;
      margin: 0;
      color: #fff;
    }
  }
  .modal-body {
    width: 600px;
  }
  .form-group {
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    color: #fff;
    .add-btn {
      position: absolute;
      right: 0;
      top: 0;
      color: #cad1d6;
      border: none;
      background-color: var(--adminprimarycolor);
      font-size: 16px;
      padding: 14px;
      border-radius: 0 4px 4px 0;
      line-height: 12px;
    }
    .list-url {
      clear: both;
      width: 80%;
      line-height: 25px;
      float: right;
      font-size: 13px;
      margin-top: -6px;
      min-height: 15px;
      color: #848890;
      margin-left: 120px;
      p {
        text-align: left;
      }
      a {
        display: inline;
        color: #848890;
      }
      span {
        font-size: 15px;
        top: -3px;
        color: #ff0202;
        left: 3px;
        position: relative;
        cursor: pointer;
      }
    }
    label {
      width: 20%;
      display: inline-block;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      float: left;
      padding: 9px;
      line-height: 20px;
    }
    input {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    select {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 14px;
      color: #cad1d6;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      text-transform: capitalize;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid rgba(170, 170, 170, 0.1);
      border-radius: 4px;
    }
    input[type='checkbox'] {
      width: auto;
      background-color: #323239;
      color: #cad1d6;
      &::before,
      &::after {
        background-color: #323239;
        color: #cad1d6;
      }
    }
    textarea {
      width: 80%;
      float: left;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      height: 100px;
      resize: none;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    button {
      padding: 5px;
      font-size: 16px;
      margin-left: 10px;
      float: right;
    }
  }
}
.top-li {
  .button-box {
    top: 0;
  }
  a {
    svg {
      font-size: 19px;
      color: #ddd;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
.sub-li {
  padding: 10px 10px 10px 0;
  margin-top: 15px;
  // height: 30px;
  .button-box {
    top: -2px;
  }
  a {
    font-size: 14px;
    svg {
      font-size: 14px;
      color: #cad1d6;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
a {
  svg {
    color: #fff;
    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}
.com-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
}
.actions-btn {
  margin-bottom: 20px;
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.line-hr {
}
.sm {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  border-top: 1px solid var(--adminprimarycolor);
  padding: 10px 0 20px 0;
  width: 100%;
  text-align: left;
  color: #fff;
  margin-top: -20px;
}
tr.no-bg {
  line-height: 80px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.mg-tp-20 {
  margin-top: 20px;
}
tr.no-bg-no-pg {
  line-height: 40px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 0 0 8px;
  }
}
td a {
  display: inline-block;
  margin-right: 8px;
}
.col-lh-40 {
  line-height: 40px;
  display: inline;
}
tr.no-bg-axnbtn {
  overflow: hidden;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.display-only {
  display: inline-block;
  top: 5px;
  clear: none;
  margin-left: 0;
}
</style>
