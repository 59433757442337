<template>
  <div
    ref="b-select-checkbox"
    class="dropdown noselect"
    style="margin-right: 20px"
    tabindex="1"
    :class="{ disabled }"
  >
    <div
      class="select plus"
      :class="[showDropdown ? 'open' : '']"
      @click="showDropdown = !showDropdown"
    >
      <font-awesome-icon
        :icon="['fas', 'plus']"
        class="droparw"
        :style="{ transform: showDropdown ? 'rotate(45deg)' : '' }"
      />
    </div>
    <ul v-if="showDropdown" class="dropdown-menu">
      <li class="norm-option" @click="selectAll">
        <font-awesome-icon
          v-if="iconPos === 'left'"
          class="check left"
          :class="{ 'check-off': !allSelected }"
          :icon="['fa', 'check']"
          size="6x"
        />
        <span class="norm-option-item">SELECT ALL</span>
        <font-awesome-icon
          v-if="iconPos === 'right'"
          class="check"
          :class="{ 'check-off': !allSelected }"
          :icon="['fa', 'check']"
          size="6x"
        />
      </li>
      <li
        v-for="(option, i) in optionsCopy"
        :key="i"
        :class="[
          option.showStar ? 'rollup-option' : 'norm-option',
          option.disabled ? 'disabled' : '',
        ]"
        @click="option.checked = !option.checked"
      >
        <div v-if="option.showStar" class="roll-preset">
          <font-awesome-icon :icon="['fas', 'star']" class="check-on checkbox-active" />
        </div>
        <font-awesome-icon
          v-if="iconPos === 'left'"
          class="check left"
          :class="{ 'check-off': !option.checked }"
          :icon="['fa', 'check']"
          size="6x"
        />
        <span v-if="option.value === 'Video Completion Rate'" class="norm-option-item"
          >Completion Rate</span
        >
        <span v-else-if="option.value === 'Video Completions'" class="norm-option-item"
          >Completions</span
        >
        <span v-else class="norm-option-item">{{ option.value }}</span>
        <font-awesome-icon
          v-if="iconPos === 'right'"
          class="check"
          :class="{ 'check-off': !option.checked }"
          :icon="['fa', 'check']"
          size="6x"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { isEqual } from 'lodash';

export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    header: {
      type: String,
      required: false,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconPos: {
      type: String,
      required: false,
      default: () => 'right',
    },
  },
  data() {
    return {
      showDropdown: false,
      optionsCopy: JSON.parse(JSON.stringify(this.options)) || [],
      oldOptionsCopy: JSON.parse(JSON.stringify(this.options)) || [],
    };
  },
  computed: {
    selected() {
      let result = '';
      if (this.allSelected) {
        result = 'ALL';
      } else {
        result = this.optionsCopy
          .filter((f) => f.checked)
          .map((m) => m.value)
          .join(', ');
      }
      return result;
    },
    allSelected() {
      let result = true;

      this.optionsCopy.forEach((option) => {
        if (!option.checked && !option.disabled) {
          result = false;
        }
      });

      return result;
    },
  },
  watch: {
    options: {
      deep: true,
      handler(n) {
        this.oldOptionsCopy = this.optionsCopy;
        this.optionsCopy = JSON.parse(JSON.stringify(n));
      },
    },
    showDropdown(n) {
      if (!n) {
        if (!isEqual(this.optionsCopy, this.oldOptionsCopy)) {
          this.$emit('selected-options', this.optionsCopy);
        }
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    onClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    },
    selectAll() {
      const selectedOptions = JSON.parse(JSON.stringify(this.optionsCopy));

      selectedOptions.forEach((option) => {
        if (option.disabled) {
          return;
        }
        option.checked = !this.allSelected;
      });

      this.optionsCopy = selectedOptions;

      this.$emit('selected-options', this.optionsCopy);
    },
  },
};
</script>

<style lang="scss" scoped>
.light-theme {
  .check {
    color: #5b6165;
    box-shadow: 0px 0px 2px #484848;
    background: #ffffff;
  }
  ul li svg {
    color: #212325;
    fill: #212325;
    &.check-off {
      color: transparent !important;
    }
  }
}
.check {
  width: 1em;
  padding: 2px;
  color: var(--primarycolor);
  border-radius: 30px;
  box-shadow: inset 0px 0px 1px 1px #2aa4af70;
  &.left {
    margin-right: 0.5rem;
  }
}

.dropdown .select {
  padding: 4px 10px !important;
  min-height: 28px !important;
}
.check-off {
  color: transparent !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.selected-opt-header {
  font-size: 14px;
  color: #707e8a;
}
.selected-opt-wrapper {
  display: inline-flex;
  width: 90%;
  white-space: nowrap;
}
.selected-opt {
  display: inline-block;
  width: 90%;
  margin-left: 0.2rem;
  overflow: hidden;
  font-size: 14px;
  color: #cad1d6;
  text-overflow: ellipsis;
  white-space: nowrap;
}
svg.reverse {
  transform: rotate(180deg);
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}
svg {
  float: right;
}
ul li svg {
  position: relative;
  font-size: 18px;
  color: #cbccd2;
  border: none;
  fill: #cbccd2;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  * {
    outline: 0;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    // min-width: 200px;
    height: 100%;
    font-size: 14px;
    color: var(--overtext);
    text-align: left;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--primarycolor);

    top: 2px !important;
    margin-right: 5px !important;
    border-radius: 4px;
    transition: all 0.3s ease;
    -webkit-touch-callout: none;
  }

  .light-theme {
    .selected-opt {
      color: #212325;
    }
    .dropdown {
      .dropdown-menu {
        min-width: 158px;
        background-color: #f9f9f9;
        li {
          color: #555;
        }
      }
      .select {
        color: #555;
        &.open {
          background-color: #ffffff;
          border-radius: 4px 4px 0 0;
          border-bottom: none;
        }
      }
    }
  }
  .dropdown .select {
    display: block;
    min-height: 40px;
    padding: 8px 10px;
    cursor: pointer;
    background-color: transparent;
    font-size: 14px;
    color: #cad1d6;
    border: none;
    border-radius: 4px;
    &.open {
      background-color: #383a44;
      border-radius: 4px 4px 0 0;
    }
    &.plus {
      color: var(--buttontext) !important;
    }
  }
  .droparw {
    position: relative;
    top: 5px;
    float: right;
    font-size: 11px;
    line-height: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .dropdown .dropdown-menu {
    position: absolute;
    left: 0;
    z-index: 9;
    min-width: 200px;
    max-height: 250px;
    margin-top: 0;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgb(45, 46, 53, 0.98);
    border-radius: 0 1px 4px 4px;
    box-shadow: 0 5px 3px 1px rgba(0, 0, 0, 0.1);
  }
  .dropdown .dropdown-menu li {
    padding: 10px;
    font-size: 13px;
    color: #cad1d6;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    svg {
      font-size: 17px;
    }
  }
  .dropdown .dropdown-menu li.norm-option {
    display: flex;

    span {
      width: 89%;
      padding-right: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .dropdown .dropdown-menu {
    padding: 0;
    list-style: none;
  }
  .dropdown .dropdown-menu li:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .dropdown .dropdown-menu li:active {
    background-color: #333;
  }
  .roll-preset {
    display: inline-block;
    padding: 0 4px;
    margin-right: 6px;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    background-color: var(--primarycolor);
    border-radius: 12px;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}
</style>
