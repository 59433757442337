import VueCookies from 'vue-cookies';
import axios from 'axios';

import * as Sentry from '@sentry/vue';
import commonHelper, { isBlank, warn } from 'adready-api/helpers/common';
import accountApi from 'adready-api/api/account';
import { COOKIE_NAME_REDIRECT_URI } from 'adready-api/helpers/cookie-session-helper';
import config from '~/config';

import forklift from '~/components/mixins/forklift-mixin';
import store from '~/store';
import {
  KEY_ACCOUNT_ID,
  KEY_ADVERTISER_ID,
  APP_FLIP_ID,
  APP_CONSOLE_ID,
  APP_ADREADY_ID,
  KEY_DEMO_ACCOUNT_ID,
  KEY_DEMO_ADVERTISER_ID,
  RANGE_CUSTOM,
  RANGE_LAST_30_DAYS,
  COMPARE_RANGE_PREV_DAY,
  COMPARE_RANGE_CUSTOM,
  VIA_MEDIA_CLIENT_NAME_FOR_API,
  VIA_ACCOUNT_ID,
} from '~/constant';
import {
  convertEpochToNYTimezone,
  isDemoInstance,
  filterAccountsAndAdvertisersForDemo,
} from '~/util/utility-functions';
import layoutHelpers from './layout-helpers';
import { initTimer, redirectToLogin } from './refresh-token';
import { clearCacheFilters } from './url-helpers';

const NEXT_URL = 'nextUrl';

// var to ensure that we run globalStartupGuard once and only once
let init;

// caches the jwt status
let hasToken = false;

/**
 * Get a valid account ID which the user has access to. If the user does not
 * have access to the given account ID, returns the first one from their list of
 * roles.
 *
 * @param {Array<UserAccount>} userAccounts
 * @param {Number} currentAccountId to test access for
 *
 * @returns {Account} valid account
 */
function getValidAccount(userAccounts, currentAccountId) {
  let currentAccount;
  if (currentAccountId) {
    // try to find the current account to ensure we have access to it
    if (userAccounts) {
      currentAccount = userAccounts.find((userAccount) => userAccount.id === currentAccountId);
      if (currentAccount && currentAccount.id === VIA_ACCOUNT_ID) {
        currentAccount.name = VIA_MEDIA_CLIENT_NAME_FOR_API;
      }
      if (!currentAccount) {
        [currentAccount] = userAccounts;
      }
      return currentAccount;
    }
  }

  if (userAccounts && userAccounts.length > 0) {
    // was invalid or not passed in, default to first accountId we have access to
    const sortedList = commonHelper.caseInsensitiveSort([].concat(userAccounts), 'name');
    // eslint-disable-next-line prefer-destructuring
    currentAccount = sortedList[0];
    if (currentAccount && currentAccount.id === VIA_ACCOUNT_ID) {
      currentAccount.name = VIA_MEDIA_CLIENT_NAME_FOR_API;
    }
  }

  return currentAccount;
}

/**
 * Get a valid advertiser ID which the user has access to. If the user does not
 * have access to the given advertiser ID, returns the first one from their list of
 * roles.
 *
 * @param {Account} currentAccount
 * @param {Number} currentAdvertiserId to test access for
 *
 * @returns {Advertiser} valid advertiser
 */
function getValidAdvertiser(currentAccount, currentAdvertiserId) {
  if (!Number.isNaN(currentAdvertiserId)) {
    // try to find the current account to ensure we have access to it
    if (currentAccount) {
      let ua = currentAccount.advertisers.find((adv) => adv.id === currentAdvertiserId);
      if (!ua) {
        const sortedList = commonHelper.caseInsensitiveSort(
          [].concat(currentAccount.advertisers),
          'name'
        );
        [ua] = sortedList;
      }
      return ua;
    }
  }

  if (currentAccount && currentAccount.advertisers && currentAccount.advertisers.length > 0) {
    // was invalid or not passed in, default to first advertiserId we have access to
    const sortedList = commonHelper.caseInsensitiveSort(
      [].concat(currentAccount.advertisers),
      'name'
    );
    return sortedList[0];
  }

  return undefined;
}

function fetchUserAccount(currentUserId, currentAccountId, currentAdvertiserId) {
  const key = 'common/userAccounts';
  const userAccounts = store.get(key);
  let retAccount = null;
  let retAdvertiser = null;
  const currentAccount = getValidAccount(userAccounts, currentAccountId);
  if (currentAccount) {
    retAccount = currentAccount;
    store.set('common/currentAccountId', currentAccount.id);
    localStorage.setItem(KEY_ACCOUNT_ID, currentAccount.id);
    store.set('common/account', currentAccount);

    const currentAdvertiser = getValidAdvertiser(currentAccount, currentAdvertiserId);
    if (currentAdvertiser && currentAdvertiser.id) {
      retAdvertiser = currentAdvertiser;
      localStorage.setItem(KEY_ADVERTISER_ID, currentAdvertiser.id);
      store.set('common/advertiser', currentAdvertiser);
    }
  }
  return { account: retAccount, advertiser: retAdvertiser };
}

async function fetchAsyncUserAccount(currentUserId, currentAccountId, currentAdvertiserId) {
  const promises = [];
  promises.push(accountApi.userAccount(currentUserId));
  return Promise.all(promises).then((responses) => {
    const demoAdvertiserMappings = store.get('common/demoAdvertiserMappings');
    const accountKeys = demoAdvertiserMappings.map((obj) => parseInt(obj.accountId, 10));
    const advertiserKeys = demoAdvertiserMappings.map((obj) => parseInt(obj.advertiserId, 10));

    const filteredUserAccounts = filterAccountsAndAdvertisersForDemo(
      responses[0],
      accountKeys,
      advertiserKeys
    );
    let retAccount = null;
    let retAdvertiser = null;
    const currentAccount = getValidAccount(filteredUserAccounts, currentAccountId);
    if (currentAccount) {
      retAccount = currentAccount;
      store.set('common/currentAccountId', currentAccount.id);
      localStorage.setItem(KEY_ACCOUNT_ID, currentAccount.id);
      store.set('common/account', currentAccount);

      const currentAdvertiser = getValidAdvertiser(currentAccount, currentAdvertiserId);
      if (currentAdvertiser && currentAdvertiser.id) {
        retAdvertiser = currentAdvertiser;
        localStorage.setItem(KEY_ADVERTISER_ID, currentAdvertiser.id);
        store.set('common/advertiser', currentAdvertiser);
      }
    }
    return { account: retAccount, advertiser: retAdvertiser };
  });
}

async function fetchDemoAccount(currentUserId, currentAccountId, currentAdvertiserId) {
  if (Number.isNaN(currentAccountId) || Number.isNaN(currentAdvertiserId)) {
    const ua = await fetchAsyncUserAccount(currentUserId, currentAccountId, currentAdvertiserId);
    currentAccountId = ua?.account?.id;
    currentAdvertiserId = ua?.advertiser?.id;
  }
  if (!currentAccountId || !currentAdvertiserId) {
    return { account: null, advertiser: null };
  }

  const demoAdvertisers = store.get('common/GET_DEMO_ADVERTISER_MAPPING_MAP');
  const keys = Object.keys(demoAdvertisers).map((key) => {
    return parseInt(key, 10);
  });

  const demoAdvertiserMapping = keys.includes(currentAdvertiserId)
    ? demoAdvertisers[currentAdvertiserId]
    : demoAdvertisers[1691];

  const dates = store.get('dashboard/dates');

  const updatedDates = {
    ...dates,
    startDate:
      demoAdvertiserMapping.startDate && demoAdvertiserMapping.endDate
        ? convertEpochToNYTimezone(new Date(demoAdvertiserMapping.startDate))
        : convertEpochToNYTimezone(new Date(new Date().setDate(new Date().getDate() - 31))),
    endDate:
      demoAdvertiserMapping.startDate && demoAdvertiserMapping.endDate
        ? convertEpochToNYTimezone(new Date(demoAdvertiserMapping.endDate))
        : convertEpochToNYTimezone(new Date(new Date().setDate(new Date().getDate() - 2))),
    compareStartDate:
      demoAdvertiserMapping.compareStartDate && demoAdvertiserMapping.compareEndDate
        ? convertEpochToNYTimezone(new Date(demoAdvertiserMapping.compareStartDate))
        : convertEpochToNYTimezone(new Date(new Date().setDate(new Date().getDate() - 32))),
    compareEndDate:
      demoAdvertiserMapping.compareStartDate && demoAdvertiserMapping.compareEndDate
        ? convertEpochToNYTimezone(new Date(demoAdvertiserMapping.compareEndDate))
        : convertEpochToNYTimezone(new Date(new Date().setDate(new Date().getDate() - 3))),
    dateRangeOption:
      demoAdvertiserMapping.startDate || demoAdvertiserMapping.endDate
        ? RANGE_CUSTOM
        : RANGE_LAST_30_DAYS,
    dateCompareOption:
      demoAdvertiserMapping.compareStartDate && demoAdvertiserMapping.compareEndDate
        ? COMPARE_RANGE_CUSTOM
        : COMPARE_RANGE_PREV_DAY,
  };
  store.set('dashboard/dates', updatedDates);

  const mappedAccountId = parseInt(currentAccountId, 10);
  const promises = [];

  // Add error handling for `accountApi.account(mappedAccountId)` call
  promises.push(accountApi.userAccount(currentUserId));

  /**
   * when user logs in as a  demo user there is mapping account associated with the demo account
   * if mapped account is not assigned to the the user server will throw unauthorised access error
   * for mapped account since not assigned to the user yet. this will make incomplete session load
   * causing globalStartupGuard to reload infinite
   * to stop infinite reload due to incomplete session data, setting up dummy values to mock the empty session.
   *
   */

  promises.push(
    accountApi.account(mappedAccountId).catch((error) => {
      console.error('Error fetching account:', error);
      // Handle the error and stop the infinite reload by preventing further execution
      store.set('common/currentAccountId', 0);
      localStorage.setItem(KEY_ACCOUNT_ID, 0);
      localStorage.setItem(KEY_ADVERTISER_ID, 0);
      localStorage.setItem(KEY_DEMO_ACCOUNT_ID, 0);
      localStorage.setItem(KEY_DEMO_ADVERTISER_ID, 0);
      store.set('common/account', 0);
      store.set('common/advertiser', 0);
      return Promise.reject(new Error('Failed to fetch account. Please try again.'));
    })
  );

  return Promise.all(promises)
    .then((responses) => {
      let retAccount = null;
      let retAdvertiser = null;
      const demoAdvertiserMappings = store.get('common/demoAdvertiserMappings');
      const accountKeys = demoAdvertiserMappings.map((obj) => parseInt(obj.accountId, 10));
      const advertiserKeys = demoAdvertiserMappings.map((obj) => parseInt(obj.advertiserId, 10));
      const userAccounts = filterAccountsAndAdvertisersForDemo(
        responses[0],
        accountKeys,
        advertiserKeys
      );
      const mappedAccount = responses[1];
      const currentAccount = getValidAccount(userAccounts, currentAccountId);
      if (currentAccount && mappedAccount) {
        const currentAdvertiser = getValidAdvertiser(currentAccount, currentAdvertiserId);
        localStorage.setItem(KEY_DEMO_ACCOUNT_ID, currentAccount.id);
        localStorage.setItem(KEY_DEMO_ADVERTISER_ID, currentAdvertiser?.id);
        store.set('common/demoSelectedAdvertiserName', currentAdvertiser?.name);

        const {
          logoFile,
          appConsoleLogoFile,
          appConsoleSquareLogoFile,
          whitelabelEnabled,
          theme,
        } = currentAccount.organization;

        mappedAccount.organization.logoFile = JSON.parse(JSON.stringify(logoFile));
        mappedAccount.organization.appConsoleLogoFile = JSON.parse(
          JSON.stringify(appConsoleLogoFile)
        );
        mappedAccount.organization.appConsoleSquareLogoFile = JSON.parse(
          JSON.stringify(appConsoleSquareLogoFile)
        );
        mappedAccount.organization.whitelabelEnabled = whitelabelEnabled;
        mappedAccount.organization.theme = JSON.parse(JSON.stringify(theme));
        mappedAccount.ziplyneAccount = currentAccount?.name;

        retAccount = mappedAccount;
        const mappedAdvertiser = getValidAdvertiser(
          mappedAccount,
          demoAdvertiserMapping.advertiserId
        );
        mappedAdvertiser.ziplyneAdvertiser = currentAdvertiser?.name;
        retAdvertiser = mappedAdvertiser;
        store.set('common/currentAccountId', mappedAccount.id);
        localStorage.setItem(KEY_ACCOUNT_ID, mappedAccount.id);
        localStorage.setItem(KEY_ADVERTISER_ID, mappedAdvertiser.id);
        if (mappedAccount.id === VIA_ACCOUNT_ID) {
          mappedAccount.name = VIA_MEDIA_CLIENT_NAME_FOR_API;
        }
        store.set('common/account', mappedAccount);
        store.set('common/advertiser', mappedAdvertiser);
      }
      return { account: retAccount, advertiser: retAdvertiser };
    })
    .catch((error) => {
      console.error('Error during fetchDemoAccount:', error);
      return { account: null, advertiser: null }; // Return null objects to stop infinite reload
    });
}

function setCurrentAccount(currentUserId, accountId) {
  // prefer the accountId that was passed in, if not valid, use value stored in localStorage
  // accountId = 77;
  let currentAccountId = accountId;
  let currentAdvertiserId = 0;
  if (!(currentAccountId && !Number.isNaN(currentAccountId))) {
    const accountKeyName = isDemoInstance() ? KEY_DEMO_ACCOUNT_ID : KEY_ACCOUNT_ID;
    currentAccountId = parseInt(localStorage.getItem(accountKeyName), 10);
    const advertiserKeyName = isDemoInstance() ? KEY_DEMO_ADVERTISER_ID : KEY_ADVERTISER_ID;
    currentAdvertiserId = parseInt(localStorage.getItem(advertiserKeyName), 10);
  }

  if (isDemoInstance()) {
    return fetchDemoAccount(currentUserId, currentAccountId, currentAdvertiserId);
  }
  return fetchUserAccount(currentUserId, currentAccountId, currentAdvertiserId);
}

/**
 * applyTheme is applicable only to non-login screens i.e. auth pages
 *
 */
const applyTheme = async () => {
  const configThemeUrl = `${config.CONFIG_API_URL}/api/theme?domain=${window.location.hostname}`;
  let configTheme = '';
  await axios.get(configThemeUrl).then((val) => {
    configTheme = val.data;
  });
  if (configTheme.primaryColor) {
    document.documentElement.style.setProperty('--primaryColor', config.PRIMARY_COLOR);
  }

  if (configTheme.overtext) {
    document.documentElement.style.setProperty('--overtext', config.BUTTON_TEXT);
  }
  const style = document.createElement('style');
  style.textContent = configTheme;
  document.head.appendChild(style);
};

/**
 * Load user session from JWT
 *
 * Decodes the JWT to extract:
 * - User ID (subject aka sub)
 *
 * @returns {Boolean} true if successful (user has valid session)
 */
export async function loadSession() {
  let decoded;
  try {
    decoded = await axios.get(`${config.ADREADY_URL}/api/token/parse`, { withCredentials: true });
    decoded = decoded?.data?.result;
    VueCookies.remove(COOKIE_NAME_REDIRECT_URI);
    initTimer(decoded.expires);
  } catch (err) {
    await applyTheme();
    warn('error decoding token:', err);
    await axios.delete(`${config.ADREADY_URL}/api/token/remove`, { withCredentials: true });
    return false;
  }

  if (!decoded) {
    return false;
  }

  const allowedAppsAccess = decoded.apps;
  if (!allowedAppsAccess.includes(APP_CONSOLE_ID)) {
    console.warn('Not Allowed to Access AppConsole');
    if (!isBlank(config.FLIP_URL) && allowedAppsAccess.includes(APP_FLIP_ID)) {
      window.location = config.FLIP_URL;
    }
    if (!isBlank(config.ADREADY_URL) && allowedAppsAccess.includes(APP_ADREADY_ID)) {
      window.location = config.ADREADY_URL;
    }
  }
  // read user ID from the token
  const currentUserId = decoded.userId;
  store.set('common/currentUserId', currentUserId);
  Sentry.setUser({ id: currentUserId.toString() });

  // load current user
  // these are currently fire-and-forget calls
  // as this data is not needed in the hot startup path
  const user = await forklift.methods.loadCurrentUser(currentUserId, true);
  const email = user && user.email ? user.email.toLowerCase() : '';
  Sentry.setUser({ id: currentUserId.toString(), email });
  forklift.methods.loadCurrentUserRoles(currentUserId, true);

  // decide if storage will be cleared
  const loggedUser = localStorage.getItem('loggedUserId');
  if (loggedUser !== null && loggedUser !== currentUserId.toString()) {
    // if not same user, clear the storage
    clearCacheFilters();
  }

  localStorage.setItem('loggedUserId', currentUserId);
  forklift.methods.fetchDemoAdvertiserMappings();

  // load user's accounts, current account, advertisers for current account, & roles
  forklift.methods.loadUserAccounts(currentUserId).then(async () => {
    // load account roles
    const { account, advertiser } = await setCurrentAccount(currentUserId, null);
    if (!account && !advertiser) {
      store.set('common/noAdvertiserAssined', true); // for showing no adv assigned msg to the user.
      store.set('common/leftNavLoading', false);
    }
    const theUser = store.get('common/currentUser');
    const theUserId = theUser && theUser.id ? theUser.id : '';
    const theEmail = theUser && theUser.email ? theUser.email.toLowerCase() : '';
    const userOrgId = theUser && theUser.organizationId ? theUser.organizationId : '';
    const userName = theUser && theUser.userName ? theUser.userName : '';

    // Initialize pendo with logged in user details
    config?.initializePendo?.(theUserId, theEmail, userOrgId);

    // Initialize ziplyne with logged in user details
    const login = localStorage.getItem('login');
    if (login) {
      localStorage.removeItem('login');
      const key = 'common/userAccounts';
      const userAccounts = store.get(key);
      let allAdvertisers = '';
      if (isDemoInstance()) {
        accountApi.userAccount(theUserId).then((res) => {
          const demoAdvertiserMappings = store.get('common/demoAdvertiserMappings');
          const accountKeys = demoAdvertiserMappings.map((obj) => parseInt(obj.accountId, 10));
          const advertiserKeys = demoAdvertiserMappings.map((obj) =>
            parseInt(obj.advertiserId, 10)
          );
          const demoAccounts = filterAccountsAndAdvertisersForDemo(
            res,
            accountKeys,
            advertiserKeys
          );
          allAdvertisers = demoAccounts.map(({ name, advertisers }) => ({
            name,
            advertisers: advertisers.map((adv) => adv.name),
          }));
          config?.ziplyneLogin?.(
            theEmail,
            userName,
            '',
            advertiser.ziplyneAdvertiser,
            allAdvertisers,
            account.ziplyneAccount
          );
        });
      } else {
        allAdvertisers = userAccounts.map(({ name, advertisers }) => ({
          name,
          advertisers: advertisers.map((adv) => adv.name),
        }));
        config?.ziplyneLogin?.(
          theEmail,
          userName,
          '',
          advertiser.name,
          allAdvertisers,
          account.name
        );
      }
    }
    const changeAdvertiser = localStorage.getItem('ChangeAdvertiser');
    if (changeAdvertiser) {
      localStorage.removeItem('ChangeAdvertiser');
      config?.ziplyneChangeAdvertiser(theEmail, advertiser.name, account.name);
    }

    forklift.methods.loadAdvertiserSolutions(true, advertiser).then(async (solutions) => {
      const activeSolutions = solutions?.filter((s) => s.state === 'ACTIVE') || [];
      const firstSolution = activeSolutions?.length ? activeSolutions[0] : undefined;
      if (!firstSolution) {
        return;
      }
      store.set('common/leftNavLoading', false);
      // await forklift.methods.loadAdvertiserCampaigns(true, account, advertiser, firstSolution.id);
    });
    forklift.methods.loadFlipPixels(advertiser);
    return true;
  });

  return true;
}

/**
 * Router guard which validates that the user is properly authenticated to
 * access the given 'to' route.
 *
 */
export function authGuard(to, from, next) {
  if (to.path === '/openid_login') {
    // Initialize pendo with anonymous user
    config?.initializeAnonymousPendo?.();
    next();
  } else if (to.meta.guest === true) {
    // Initialize pendo with anonymous user
    config?.initializeAnonymousPendo?.();
    next();
  } else if (!hasToken) {
    // Initialize pendo with anonymous user
    config?.initializeAnonymousPendo?.();
    // everything else requires user to be logged in
    localStorage.setItem(NEXT_URL, to.fullPath);
    // next({ name: 'Login' });
    const { mode } = to.query;
    redirectToLogin(mode);
  } else {
    // allow authenticated user to continue
    const nextUrl = localStorage.getItem(NEXT_URL);
    if (!isBlank(nextUrl)) {
      // have a stored redirect url, use it
      localStorage.removeItem(NEXT_URL);
      next(nextUrl);
    } else {
      // just continue on to the requested route.
      // this is the most common scenario for a logged-in user.
      next();
    }
  }
}

/**
 * Global router guard which runs exactly *once*, when the application is first
 * loaded. That is, when processing the very first route. Anything which needs
 * to run at the earliest possible time, before loading any Vue components
 * should happen here.
 *
 * Note, however, that by this point, the route is already selected, however it
 * can still be modified (by passing the next func from the guard method below).
 *
 * Currently it:
 * - loads theme
 * - loads layout
 * - handles the AuthN check for secure routes (reading the JWT)
 * - attempts to bust caches on version changes
 */
export async function globalStartupGuard(to) {
  if (hasToken === false) {
    // always reload token first, when not logged in
    hasToken = await loadSession(parseInt(to.query.accountId, 10));
  }

  window.document.title = config.APP_NAME || 'Digital Remedy';

  if (init !== undefined) {
    return init;
  }

  // load theme
  layoutHelpers.updateTheme();

  if (!hasToken) {
    init = false;
    return false;
  }
  return true;
}
