<template>
  <div
    ref="b-dropdown-flat"
    class="dropdown noselect"
    style="width: 100%; max-width: 100%;"
    tabindex="1"
    :class="{ disabled }"
  >
    <div
      :id="componentId"
      class="select pe-select"
      :class="[showDropdown ? 'open' : 'closed']"
      @click="showDropdown = !showDropdown"
    >
      <span class="top-span">{{ selectedOptions }}</span>
      <font-awesome-icon
        :icon="['far', 'chevron-down']"
        class="droparw"
        :class="showDropdown ? 'rotate-chevron' : ''"
      />
    </div>
    <ul
      v-if="showDropdown"
      :id="dropdownId"
      :class="[allowMultiSelect ? 'dropdown-menu-multi' : 'dropdown-menu']"
    >
      <li
        v-if="allowMultiSelect && showAll"
        class="pe pe3b drp-itm2 dropdown-content-selected"
        @click="selectAll"
      >
        <font-awesome-icon
          v-if="checkBoxPosition === 'left'"
          class="drop-ck show left"
          :class="{ 'check-off': !allSelected }"
          :icon="['fas', 'check']"
          size="6x"
        />
        <font-awesome-icon
          v-if="showOptionWithIcon"
          class="sbm_i"
          :icon="['fas', 'check-double']"
        />
        <span class="norm-option-item">{{ customSelectAllText }}</span>
        <font-awesome-icon
          v-if="checkBoxPosition === 'right'"
          class="drop-ck show"
          :class="{ 'check-off': !allSelected }"
          :icon="['fa', 'check']"
          size="6x"
        />
      </li>
      <li
        v-for="(option, i) in optionsCopy"
        :key="i"
        class="pe pe3 drp-itm2 dropdown-content-selected"
        :class="[option.disabled ? 'disabled' : '']"
        @click="onOptionClick(option, i)"
        @mouseover="(el) => handleMouseOver(el, i + 1, option.value || option)"
        @mouseleave="handleMouseLeave"
      >
        <font-awesome-icon
          v-if="allowMultiSelect && checkBoxPosition === 'left'"
          class="drop-ck show left"
          :class="{ 'check-off': !option.checked }"
          :icon="['fa', 'check']"
          size="6x"
        />
        <font-awesome-icon v-if="showOptionWithIcon" class="sbm_i" :icon="['fas', option.icon]" />
        <span class="norm-option-item">{{ option.value || option }}</span>
        <font-awesome-icon
          v-if="allowMultiSelect && checkBoxPosition === 'right'"
          class="drop-ck show"
          :class="{ 'check-off': !option.checked }"
          :icon="['fa', 'check']"
          size="6x"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import { uuid } from '~/helpers/global/misc-helpers';

export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    showAll: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkBoxPosition: {
      type: String,
      required: false,
      default: () => 'left',
    },
    componentId: {
      type: String,
      required: false,
      default: () => 'dropdownComponent',
    },
    showOptionWithIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowMultiSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    customSelectAllText: {
      type: String,
      required: false,
      default: 'All',
    },
    hideSelections: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    let selectedOption = '';
    if (!this.allowMultiSelect && this.options.length) {
      const defaultSelectedOption = this.options.find((t) => t.selected);
      selectedOption = defaultSelectedOption ? defaultSelectedOption.value : this.options[0].value;
    }
    return {
      showDropdown: false,
      optionsCopy: JSON.parse(JSON.stringify(this.options)) || [],
      activeEl: { style: { display: 'none' }, text: '' },
      dropdownId: uuid(),
      selectedOption,
      newSelected: [],
      oldSelected: [],
    };
  },
  computed: {
    selectedOptions() {
      if (!this.allowMultiSelect) {
        return this.selectedOption;
      }
      if (this.hideSelections) {
        return '- Select Metrics -';
      }
      let result = '';
      if (this.allSelected) {
        result = 'All';
      } else {
        result = this.optionsCopy
          .filter((f) => f?.checked)
          .map((m) => m.value)
          .join(', ');
      }
      return result;
    },
    allSelected() {
      let result = true;
      this.optionsCopy.forEach((option) => {
        if (!option?.checked && !option.disabled) {
          result = false;
        }
      });

      return result;
    },
  },
  watch: {
    options: {
      deep: true,
      handler(n) {
        this.optionsCopy = JSON.parse(JSON.stringify(n));
        if (!this.allowMultiSelect && n.length) {
          const defaultSelectedOption = n.find((t) => t.selected);
          this.selectedOption = defaultSelectedOption ? defaultSelectedOption.value : n[0].value;
        }
      },
    },
    disabled(newVal, oldVal) {
      if (newVal !== oldVal && newVal && this.showDropdown) {
        this.showDropdown = false;
      }
    },
    showDropdown(n) {
      if (!n) {
        if (!isEqual(this.newSelected, this.oldSelected)) {
          this.$emit('selected-options', this.optionsCopy, false);
        }
        this.oldSelected = this.newSelected;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside);
    this.newSelected = this.optionsCopy.filter((item) => item.checked);
    this.oldSelected = this.newSelected;
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    onOptionClick(option) {
      if (this.allowMultiSelect) {
        option.checked = !option.checked;
        this.handleMouseLeave();
        // this.oldSelected = this.newSelected;
        this.newSelected = this.optionsCopy.filter((item) => item.checked);
      } else {
        this.selectedOption = option.value || option;
        this.$emit('on-change', option.id || option, option.value || option);
        this.showDropdown = false;
      }
    },
    onClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    },
    handleMouseOver(el, i, text) {
      const ulElement = document.getElementById(this.dropdownId);
      if (!text) {
        return;
      }
      this.activeEl = {
        style: {
          top: `${i * 39.2 - ulElement.scrollTop + 80}px`,
          left: `${25}px`,
          display: 'block',
        },
        text,
      };
    },
    handleMouseLeave() {
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    selectAll() {
      const selectedOptions = JSON.parse(JSON.stringify(this.optionsCopy));

      selectedOptions.forEach((option) => {
        if (option.disabled) {
          return;
        }
        option.checked = !this.allSelected;
      });

      this.optionsCopy = selectedOptions;

      this.$emit('selected-options', this.optionsCopy, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.check {
  width: 1em;
  padding: 2px;
  color: var(--primarycolor);
  border-radius: 30px;
  box-shadow: inset 0px 0px 1px 1px #2aa4af70;
  &.left {
    margin-right: 0.5rem;
  }
  &.wl {
    color: var(--primarycolor) !important;
  }
}
.check-off {
  color: transparent !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.selected-opt-header {
  font-size: 14px;
  color: #707e8a;
}
.selected-opt-wrapper {
  display: inline-flex;
  width: 90%;
  white-space: nowrap;
}
.selected-opt {
  display: inline-block;
  width: 90%;
  margin-left: 0.2rem;
  overflow: hidden;
  font-size: 14px;
  color: #cad1d6;
  text-overflow: ellipsis;
  white-space: nowrap;
}
svg.reverse {
  transform: rotate(180deg);
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  * {
    outline: 0;
  }
  .dropdown {
    min-width: 150px;
    max-width: 240px;
    display: inline-block;
    border-radius: 6px;
    transition: all 0.3s ease;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: var(--primarydark2);
    text-align: left;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Opera and Firefox */
  }
  .dropdown .select {
    display: block;
    cursor: pointer;
    padding: 8px 20px 8px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dropdown .select .droparw {
    transition: all 0.2s ease-in-out;
  }
  .dropdown .select .droparw.rotate-chevron {
    transform: rotate(180deg);
  }
  .dropdown .dropdown-menu,
  .dropdown .dropdown-menu-multi {
    position: absolute;
    left: 0;
    z-index: 999;
    width: calc(100% + 2px);
    max-height: 338px;
    margin-top: 0px;
    margin-left: -1px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 0 1px 4px 4px;
    box-shadow: 0px 5px 3px 1px rgb(115 124 143 / 10%);
    border-right: 1px solid #c6d2d8;
    border-left: 1px solid #c6d2d8;
    border-bottom: 1px solid #c6d2d8;
  }
  .dropdown .dropdown-menu li,
  .dropdown .dropdown-menu-multi li {
    padding: 5px 10px;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    border-bottom: 1px solid #2b355814;
  }
  .dropdown .dropdown-menu-multi li {
    padding: 10px 10px 10px 30px;
    font-weight: 500;
  }
  .dropdown .dropdown-menu li.dropdown-content-selected {
    display: flex;
  }
  .dropdown .dropdown-menu,
  .dropdown .dropdown-menu-multi {
    padding: 0;
    list-style: none;
  }
  .dropdown .dropdown-menu,
  .dropdown .dropdown-menu-multi {
    padding: 0px 0px 0px 0px;
    list-style: none;
  }
  .dropdown .dropdown-menu li:hover,
  .dropdown .dropdown-menu-multi li:hover {
    white-space: normal;
    background-color: rgb(116 116 143 / 6%) !important;
  }
  .dropdown .dropdown-menu li:hover,
  .dropdown .dropdown-menu-multi li:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .dropdown .dropdown-menu li:active,
  .dropdown .dropdown-menu-multi li:active {
    background-color: #333;
  }

  .drop-ck {
    float: right;
    position: relative;
    top: 2px;
    color: var(--primarycolor);
    border: 0px solid;
    box-shadow: inset 0px 0px 1px 1px var(--primarycolor);
    padding: 2px 3px 3px;
    top: 2px;
    left: 8px;
    background: transparent;
    font-size: 11px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 10px;
    width: 11px;
    height: 11px;
  }

  .dropdown-menu-multi .drop-ck {
    float: none;
    color: var(--primarycolor);
    border: 0px solid;
    box-shadow: inset 0px 0px 1px 1px var(--primarycolor);
    padding: 2px 3px 3px;
    top: 12px;
    left: 8px;
    background: transparent;
    font-size: 11px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 10px;
    width: 11px;
    height: 11px;
    position: absolute;
  }

  .dropdown-menu-multi .sbm_i {
    float: none;
    background: transparent;
    display: inline-block;
    margin-left: 8px;
    margin-right: 4px;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}
</style>
